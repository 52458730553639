.header {
    margin-top: 8rem;
    letter-spacing: 0.025em;
}

.header-text {
    text-align: center;
    font-size: 3rem;
    color: #000;
    font-weight:normal;
    text-transform: capitalize;

}

.header-body-text {
    text-align: center;
    font-size: 2rem;
    color: #000;
    font-weight:300;
}

/* Media query for tablet screens */
@media (max-width: 768px) {
    .header {
        margin-top: 3rem;
    }
    .header-text {
        font-size: 2rem;
    }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .header {
        margin-top: 3rem;
    }
    .header-text {
        font-size: 1.5rem;
    }
    .header-body-text {
        font-size: 1rem;
        padding: 0px 5px;
        font-weight: 300;
    }
}
