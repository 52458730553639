.product-carousel-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box; 
    max-width: 100%; 
    overflow-x: hidden; 
}

.product-card-container {
    box-sizing: border-box;  margin-bottom: 20px;}

@media (max-width: 1200px) {
    .product-card-container {
        flex: 1 1 calc(50% - 20px); 
        
    }
}

@media (max-width: 768px) {
    .product-card-container {
        flex: 1 1 calc(100% - 20px);
    }
}
