.formListContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #000;
    font-weight:normal;
}

.formListsection {
    margin-bottom: 50px;
}

h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #000;
    font-weight:normal;
}

.listText {
    font-size: 16px;
    color: #000;
    font-weight:300;
}

.a {
    color: #000;
    font-weight: bolder;
    text-decoration: none;
}


 

  @media (min-width: 992px) {

    .formListHeader {
        font-size: 1.5rem;
        margin-bottom: 20px;
        text-align: left;
    
    }
    }
  

@media (max-width: 480px) {
    .formListHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
    
}
/* Stacked layout for smaller screens */
@media (max-width: 750px) {
    .formListHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
}