.relatedItemsHeader{
    font-size: 1rem; 
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    text-transform:inherit;
}

.relatedItemsContainer {
    padding: 20px;
}

.relatedItemsGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.relatedItem {
    flex: 1 1 calc(20% - 10px); /* Five items per row */
    margin: 5px;
}

.productLink {
    text-decoration: none;
    color: inherit;
}

.productImage {
    width: 100%;
    height: auto;
}

.productInfo {
    text-align: center;
    margin-top: 10px;
}

.productTitle {
    font-size: 0.8em;
    /* font-weight: lighter; */
    margin: 0;
}

.productPrice {
    margin: 0;
    font-size: 0.8em;
    color: #030303;
    font-weight: bold;
    padding-bottom: 1rem;
}


.showMoreContainer {
    text-align: center;
    margin-top: 20px;
}

.showMoreLink {
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    color: #007bff;
}

.showMoreLink:hover {
    color: #0056b3;
}
.relatedItemsGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 images per row on large screens */
    gap: 20px; /* Space between the images */
  }
  
  @media (max-width: 1200px) {
    .relatedItemsGrid {
      grid-template-columns: repeat(4, 1fr); /* 4 images per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .relatedItemsGrid {
      grid-template-columns: repeat(3, 1fr); /* 3 images per row on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .relatedItemsGrid {
      grid-template-columns: repeat(2, 1fr); /* 2 images per row on mobile phones */
    }
  }
  
