.signin-btn {
    background-color: #ffffff;
    border: solid 1px gray;
    color: gray;
    padding: 0.5rem 0.5rem;
    border-radius: 0.2rem;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}
.signin-btn:hover {
    background-color: #f8f8f8;
}

@media (max-width: 600px) {
   .signin-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 0.7rem 1.5rem;
    }
}
