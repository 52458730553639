/* Blog.module.css */

.container {
    display: flex;
    gap: 20px;
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.blogContent {
    flex: 3;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 80px;
    font-family: 'Merriweather', serif;
}

.blogCards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mainBlog {
    margin-bottom: 20px;
}

.blogImage {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
}

.blogHeader {
    font-size: 2rem;
    margin-top: 60px;
    text-align: center;
    color: #505053;
    font-weight: bold;
}

.blogText {
    font-size: 1.125rem;
    color: #505053;
}

.blogText p {
    margin-bottom: 50px;
    color: #505053;
}
.blogText a {
    margin-bottom: 50px;
    color: #6060ba;
}
.blogText h4 {
    font-size: 1.75rem;
    margin: 30px 0 15px;
    color: #505053;
    font-weight: bold;
}

.blogText ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 50px;
    color: #505053;

}

.blogText li {
    font-size: 16px;
    color: #505053;
}

.blogCard {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin: 0 auto;
    background-color: white;
}

.blogCard:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.cardImage {
    width: 100%;
    height: 60%;
    object-fit: cover;
}

.cardContent {
    padding: 15px;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cardHeader {
    font-size: 1.125rem;
    margin: 0;
    color: #333;
    font-weight: bold;
}

.cardText {
    font-size: 0.875rem;
    margin: 10px 0;
    color: #333;
}

.viewMore {
    color: #007BFF;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .blogHeader {
        font-size: 1.75rem;
    }

    .blogText {
        font-size: 1rem;
    }

    .cardHeader {
        font-size: 1rem;
    }

    .cardText {
        font-size: 0.75rem;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .blogHeader {
        font-size: 1.5rem;
    }

    .blogText {
        font-size: 0.9375rem;
    }

    .cardHeader {
        font-size: 0.875rem;
    }

    .cardText {
        font-size: 0.625rem;
    }
    .blogContent{
        padding: 20px;
    }
    .blogText p {
        margin-bottom: 50px;
        color: #505053;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .blogHeader {
        font-size: 1.25rem;
    }

    .blogText {
        font-size: 0.875rem;
    }

    .cardHeader {
        font-size: 0.825rem;
    }

    .cardText {
        font-size: 0.7rem;
    }
    .blogContent{
        padding: 20px;
    }
    .blogText h4 {
        font-size: 1rem;
        margin-bottom: 2px;
        color: #505053;
        font-weight: bold;
    }
    .blogText p {
        margin-bottom: 50px;
        color: #505053;
        font-size: 0.75rem;
    }

}
