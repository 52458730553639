.product-carousel-container {
    margin: 0 auto;
    padding-bottom: 1rem;
    max-width: 100%;
}

.product-list-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.product-card-container {
    width: 18%; /* Five items per row on large screens */
    margin-bottom: 1rem;
}

.header-title {
    text-align: center;
    font-size: 3rem;
    color: #000;
    font-weight:normal;
    text-transform: capitalize;

}
@media (max-width: 1280px) {
    .product-card-container {
        width: 23%; /* Four items per row on medium screens */
    }
    .header-title{
        text-align: center;
        font-size: 2rem;
        color: rgb(59, 58, 58);
        font-weight:normal;
        text-transform: capitalize;
    }
}

@media (max-width: 1024px) {
    .product-card-container {
        width: 30%; /* Three items per row on small screens */
    }
}

@media (max-width: 768px) {
    .product-card-container {
        width: 48%; /* Two items per row on mobile screens */
    }
}

@media (max-width: 480px) {
    .product-card-container {
        width: 48%; /* Two items per row on mobile screens */
    }
}
