.product-card {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 100%;
    position: relative;
}

.product-card:hover {
    transform: translateY(-5px);
    /* box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); */
    text-decoration: none;
}

.card-content {
    text-align: center;
    position: relative;
}

.product-card img {
    width: 15rem;
    height: 15rem;
    /* object-fit: cover; */
    border-radius: 0.2rem;
    justify-content: center;
    /* display: flex; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(242, 241, 241, 0.6);
    color: rgb(59, 58, 58);
    text-align: center;
    padding: 10px 0;
}

.product-title {
    margin: 0;
    padding-top: 0.5rem;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: lighter;
}

.product-card-cost{
    font-weight: bold;
    margin: 0;

}

@media (max-width: 720px) {
    .product-card img {
        width: 9rem;
        height: 9rem; 
    }
    .product-title {
        margin: 0;
        font-size: 0.9rem;
        font-weight:lighter
    }
    .title-overlay {
        padding: 2px 0;
    }
    .product-card-title{
        font-size: 12px;
    }
}

