.policyModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto; /* Ensure the modal itself is scrollable */
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh; /* Limit the height of the modal */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
}

.checkboxContainer {
    margin: 20px 0;
}

.centerBtn {
    text-align: center;
    margin-top: 20px;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.closeButton {
    background-color: #6c757d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submitButton:hover, .closeButton:hover {
    opacity: 0.8;
}
