.heroContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px;
}

.textContainer {
    flex: 1;
    padding: 20px;
    text-align: center; 
}

.headerText {
    font-size: 36px;
    margin-bottom: 1rem;
    text-align: left;
}

.subText {
    font-size: 20px;
    color: #000;
    font-weight:normal;
    margin-bottom: 1rem;
    text-align: left;
    line-height: 1.5;
}

.ctaButton {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ctaButton:hover {
    background-color: #0056b3;
}

.imageContainer {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.heroImage {
    max-width: 80%;
    height: auto;
    border-radius: 1rem;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
    .heroContainer {
        flex-direction: column; 
    }

    .textContainer {
        margin-bottom: 20px;
    }
    .headerText { 
        font-size: 1.5rem;
        color: #000;
    }
    .subText {
        font-size: 1rem;
        color: #000;
        font-weight:normal;
        padding: 0px 5px 0px 5px;
    }
}
