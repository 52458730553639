/* src/NavigationBar.css */
.cart-icon {
    position: relative;
    font-size: 24px;
}
.cart-item-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    min-width: 10px; /* Minimum width for single digit */
    min-height: 20px; /* Minimum height for single digit */
    padding: 0 5px; /* Add horizontal padding for larger numbers */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
}

 

.navbar {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border-bottom: 1px solid #f2f2f4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.left {
    display: flex;
    align-items: center;
}

.logo img {
    width: 50px;
    height: 50px;
}

.companyName {
    font-size: 18px;
    margin-left: 10px;
}

.link {
    text-decoration: none;
    color: #060b93;
    font-weight: bold;
}

.navLinks {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navItem {
    position: relative;
}

.navItem a {
    text-decoration: none;
    color: #fff;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navItem a:hover {
    background-color: #3288da;
    color: white;
    border-color: #b0becc;
}

.navbar-logo {
    font-size: 24px;
}

.navbar-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 14px;
}

.navbar-links li {
    margin: 15px 20px;
    position: relative;
}

.navbar-links a {
    text-decoration: none;
    color: #060b93;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #2e2d2d;
}

.navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-image: linear-gradient(to right, rgb(29, 40, 147) 0%, rgb(64, 141, 225) 100%);
    margin: 3px 0;
    border-color: #7d0e0e;
    border: 1px;
}

.logo-shorten {
    display: none;
}

@media (max-width: 1355px) {
    .logo img {
        width: 50px;
        height: auto;
    }
    
    .navbar-links {
        display: none;
        flex-direction: column;
        background-color: #fff;
        position: absolute;
        top: 75px;
        left: 50%;
        right: 0px;
        text-align: center;
        border-bottom: 0.5px solid #cacbcd96;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-links.active {
        display: flex;
        z-index: 10000;
    }

    .navbar-toggle {
        display: block;
        padding-right: 2rem;
    }
}

@media (max-width: 466px) {
    .logo-shorten {
        display: block;
    }
    .logo-regular {
        display: none;
    }
}

@media (min-width: 992px) {
    .logo img {
        width: 100px;
        height: auto;
    }
    .navbar {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 0px 5px;
        border-bottom: 1px solid #f2f2f4;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }
}
