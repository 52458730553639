
@font-face {
  src: url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
  font-style: normal;
  font-display: swap;
  font-family: 'Montserrat';
  font-weight: bold;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100%;
  margin: 0;
  font-family: 'Montserrat';

}
.html{
  margin: 0;
  scroll-behavior: smooth;
}


#root {
  min-height: 100%;
}