.btn {
    /* background-color: #1767b2;
    color: rgb(255, 255, 255); */
    background-color: #32CD32;
    color: rgb(0, 0, 0);
    margin-top: 1rem;
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 0.2rem;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 1rem;
    max-width: 100%;
    display: inline-block;
    text-align: center;
}

.white-no-bg {
    background-color: #1767b2;
    border: 1px solid #1767b2;
    color: white;
    padding: 0.7rem 2rem;
    border-radius: 0.2rem;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
}

.white-no-bg:hover {
    background-color: #1767b2;
    border: 1px solid #1767b2;
    color: white;
    padding: 0.7rem 2rem;
    border-radius: 0.2rem;
    font-size: 18px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .btn, .white-no-bg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 0.7rem 1.5rem;
    }
}
