/* Container for the cart page */
.cartContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 10px; /* Rounded corners */
    margin-top: 30px; /* Space from the top */
}

/* Cart items list */
.cartItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Individual cart item */
.cartItem {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff; /* White background */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for each item */
    transition: box-shadow 0.3s ease;
}

/* Add hover effect for cart item to give it a slight "lift" */
.cartItem:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Elevated shadow on hover */
}

/* Cart item details (image, title, description) */
.cartItemDetails{
    display: flex;
    gap: 15px;
    flex-grow: 1;
    max-width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: #000000; /* Amazon blue */
    margin: 0; /* Ensure no margin between the lines */
}
.cartItemTitle{
    font-size: 14px;
}
/* Cart item image styling */
.cartItemImage img {
    width: 100px;
    height: auto;
    border-radius: 8px;
}
/* Description part */
.cartItemDescription{
    font-size: 12px;
}

/* Action area with price, quantity, and remove button */
.cartItemActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    flex-grow: 0;
    max-width: 30%;
}

/* Price styling */
.cartItemPrice p {
    font-size: 12px;
    font-weight: 600;
}

/* Quantity controls */
.cartItemQuantity {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Quantity buttons */
.cartItemQuantity button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: #000;
}
.cartItemQuantity button:hover {
    background-color: #dedddd;
    border: 1px solid #ccc;
}
/* Quantity text */
.cartItemQuantity span {
    font-size: 12px;
    font-weight: 600;
}

/* Remove button */
.removeButton {
    background-color: #f02828;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;

}

.removeButton:hover {
    background-color: #a30404;
}

/* Cart summary (total) */
.cartSummary {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* Subtotal and checkout button */
.cartSummary p {
    font-size: 16px;
    font-weight: 700;
}

/* Checkout button styling */
.checkoutButton {
    background-color: #358ED7;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.checkoutButton:hover {
    background-color: #2e83c8;
}

/* Link hover effect */
.productLink:hover .cartItemDescription p {
    text-decoration: underline;
}

.emptyCartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 80%;
    max-width: 600px;
    margin: 2rem auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.emptyCartIcon {
    color: #1767b2;; /* Tomato color for the icon */
    margin-bottom: 1rem;
}

h2 {
    /* font-size: 2rem; */
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.startShoppingBtn {
    padding: 12px 24px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    background-color: #1767b2;;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.startShoppingBtn:hover {
    background-color: #155fa5;;
}

.startShoppingBtn:focus {
    outline: none;
}


/* Tablet or smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    .cartItem {
        gap: 15px;
    }

    .cartItemDetails {
        max-width: 100%;
    }

    .cartItemDescription{
        font-size: 12px;
    }
    .cartItemTitle{
        font-size: 14px;
    }
    .cartItemActions {
        max-width: 100%;
        flex-direction: row;
        gap: 10px;
    }

    .cartItemImage img {
        width: 80px;
    }

    .cartItemQuantity button {
        font-size: 12px;
        padding: 4px 8px;
    }

    .cartItemQuantity span {
        font-size: 12px;
    }

    .cartSummary {
        flex-direction: column;
        align-items: flex-end;
    }

    button {
        font-size: 14px;
        padding: 10px 20px;
    }
}

/* Mobile screens (max-width: 480px) */
@media (max-width: 480px) {
    .cartItem {
        flex-direction: column;
        gap: 10px;
    }

    .cartItemDetails {
        max-width: 100%;
        font-size: 13px;
    }
    .cartItemDescription{
        font-size: 12px;
    }
    .cartItemTitle{
        font-size: 14px;
    }
    .cartItemImage img {
        width: 100%;
    }

    .cartItemActions {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .cartItemPrice p {
        font-size: 12px;
    }

    .cartItemQuantity {
        flex-direction: row;
        gap: 8px;
    }

    .cartItemQuantity button {
        font-size: 10px;
        padding: 3px 6px;
    }

    .cartItemQuantity span {
        font-size: 12px;
    }

    button {
        font-size: 12px;
        padding: 12px 18px;
    }

}

@media (max-width: 320px) {
    .cartItem {
        gap: 10px;
    }
    .cartItemDescription{
        font-size: 12px;
    }
    .cartItemTitle{
        font-size: 14px;
    }
    
    /* Cart item image styling */
    .cartItemImage img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .cartItemActions {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .cartItemPrice p {
        font-size: 12px;
    }

    .cartItemQuantity {
        flex-direction: row;
        gap: 8px;
    }

    .cartItemQuantity button {
        font-size: 10px;
        padding: 3px 6px;
    }

    .cartItemQuantity span {
        font-size: 12px;
    }

    button {
        font-size: 12px;
        padding: 12px 18px;
    }

}
