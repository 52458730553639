.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.spinner {
  width: 15px;
  height: 15px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  margin-right: 15px; }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; 
    text-align: center;
  color: #6c757d;
}

.title {
  font-size: 2rem;
  color: #28a745;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.manualRedirect {
  margin-top: 20px;
}

.manualRedirect p {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 10px;
}

.redirectButton {
  padding: 12px 24px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.redirectButton:hover {
  background-color: #2980b9;
}

@media (max-width: 600px) {
  .content {
    padding: 20px;
    max-width: 90%; }

  .title {
    font-size: 1.8rem;}

  .subtitle {
    font-size: 1rem;
  }

  .loading {
    font-size: 1rem;
  }

  .redirectButton {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

