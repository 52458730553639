.background-container{
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;

}