.container {
    margin: 0 auto;
    padding: 15px;
    display: flex;
    max-width: 100%;
}
.product-quantity select {
    padding: 12px 20px;
    border-radius: 8px;
    border: 2px solid #E1E8EE;
    background-color: #F7F8FA;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    max-width: 150px;
    transition: all 0.3s ease;
}

.product-quantity {
    margin-bottom: 20px;
}

.product-quantity select::-ms-expand {
    display: none; }

.product-quantity select::after {
    content: '▼'; 
     font-size: 18px;
    color: #333;
    position: absolute;
    right: 20px;
    top: 12px;
    pointer-events: none;
}

.product-quantity select:hover,
.product-quantity select:focus {
    border-color: #358ED7;
    outline: none;
    background-color: #FFF;
}

.product-quantity select option {
    background-color: #F7F8FA;
    font-size: 16px;
    padding: 8px;
    color: #333;
}

.product-quantity select option:focus {
    background-color: #358ED7;
    color: #fff;
}

.left-column {
    width: 50%;
    position: relative;
}

.right-column {
    width: 35%;
    margin-top: 60px;
}

.left-column img {
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    height: 30rem;
    width: 30rem;
    padding-top: 2rem;
}

.product-mini-img {
    width: 8rem;
    height: 8rem;
    cursor: pointer;
    max-width: 100%;
}

.product-description {
    border-bottom: 1px solid #E1E8EE;
    margin-bottom: 20px;
    max-width: 100%;
}

.product-description span {
    font-size: 12px;
    color: #000;
    font-weight:normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}

.product-description h1 {
    font-weight: 300;
    font-size: 52px;
    color: #000;
    font-weight:normal;
    letter-spacing: -2px;
}

.product-description p {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px;
}

.product-color {
    margin-bottom: 30px;
}

.product-color p {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    font-weight:normal;
}

.color-choose div {
    display: inline-block;
}

.color-choose input[type="radio"] {
    display: none;
}

.color-choose input[type="radio"] + label span {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #064c93;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
}

.color-choose input[type="radio"]#red + label span {
    background-color: #C91524;
}

.color-choose input[type="radio"]#blue + label span {
    background-color: #314780;
}

.color-choose input[type="radio"]#black + label span {
    background-color: #323232;
}

.color-choose input[type="radio"]:checked + label span {
    background-repeat: no-repeat;
    background-position: center;
}

.include-choose {
    margin-bottom: 20px;
}

.include-choose button {
    border: 2px solid #E1E8EE;
    border-radius: 6px;
    padding: 13px 20px;
    font-size: 14px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.46);
    cursor: pointer;
    transition: all .5s;
}

.include-choose button:hover,
.include-choose button:active,
.include-choose button:focus {
    border: 2px solid #86939E;
    outline: none;
    text-decoration: none;
}

.include-config {
    margin-bottom: 20px;
}

.include-config p {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
}

.include-config a {
    color: #358ED7;
    text-decoration: none;
    font-size: 12px;
    position: relative;
    margin: 10px 0;
    display: inline-block;
}

.include-config a:before {
    content: "?";
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid rgba(53, 142, 215, 0.5);
    display: inline-block;
    text-align: center;
    line-height: 16px;
    opacity: 0.5;
    margin-right: 5px;
}

.product-price {
    align-items: center;
}

.product-price p {
    font-size: 30px;
    line-height: 1.5;
    color: #000;
    font-weight:normal;
}

.product-price span {
    font-size: 26px;
    font-weight: 300;
    color: #000;
    margin-right: 20px;
}

.cart-btn {
    display: inline-block;
    background-color: #7DC855;
    border-radius: 6px;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 12px 30px;
    transition: all .5s;
}

.cart-btn:hover {
    background-color: #64af3d;
}

@media (max-width: 600px) {
    .product-price p {
       text-align: center;
    }
}

@media (max-width: 940px) {
    .container {
        flex-direction: column;
    }

    .left-column,
    .right-column {
        width: 100%;
    }

    .left-column img {
        width: 100%;
        height: 100%;
        right: 0;
        top: -65px;
        left: initial;
    }
}

@media (max-width: 535px) {
    .left-column img {
        width: 100%;
        top: -85px;
    }

    .product-description h1 {
        font-weight: 300;
        font-size: 30px;
        color: #000;
        letter-spacing: -1px;
    }
}

@media (max-width: 1140px) {
    .left-column img {
        top: -85px;
    }
}
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above everything else */
    animation: fadeIn 0.3s ease-out; /* Smooth fade-in animation */
}

/* Modal Content */
.modalContent {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease-out; /* Smooth slide-in animation */
}

/* Modal Buttons */
.modalButtons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

/* Button Styling */
.modalButtons button {
    background-color: #358ED7;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modalButtons button:hover,
.modalButtons button:focus {
    background-color: #2a73a0;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.container {
    margin: 0 auto;
    padding: 15px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap; /* Added to allow better wrapping of columns */
    gap: 20px; /* Adds space between the left and right columns */
}

/* Left Column Styling */
.left-column {
    flex: 1;
    position: relative;
    padding-top: 2rem;
}

.left-column img {
    width: 100%;
    max-width: 30rem;
    height: auto;
    transition: all 0.3s ease;
    object-fit: cover;
}

/* Right Column Styling */
.right-column {
    flex: 1;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-description {
    border-bottom: 1px solid #E1E8EE;
    margin-bottom: 20px;
    max-width: 100%;
}

.product-description span {
    font-size: 12px;
    color: #000;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}

.product-description h1 {
    font-weight: normal;
    font-size: 36px;
    color: #000;
    letter-spacing: -2px;
}

.product-description p {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    line-height: 24px;
}

.product-configuration {
    margin-bottom: 20px;
}

.product-color {
    margin-bottom: 20px;
}

.product-color p {
    font-size: 16px;
    color: #000;
    font-weight: normal;
}

.product-img-choose {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.product-mini-img {
    width: 8rem;
    height: 8rem;
    cursor: pointer;
    max-width: 100%;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.product-mini-img:hover {
    opacity: 0.7;
}

/* Styling for quantity selection */
.product-quantity select {
    padding: 12px 20px;
    border-radius: 8px;
    border: 2px solid #E1E8EE;
    background-color: #F7F8FA;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    max-width: 150px;
    transition: all 0.3s ease;
}

/* Social Share Section */
.social-share {
    margin-top: 20px;
}

.social-share p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
}

.social-icons {
    display: flex;
    gap: 15px;
}

.social-icons a {
    font-size: 32px;
    color: #333;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #358ED7;
}

.copy-link-button {
    background-color: #358ED7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-link-button:hover {
    background-color: #2a73a0;
}

/* Modal Overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    animation: fadeIn 0.3s ease-out;
}

/* Modal Content */
.modalContent {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease-out;
}

/* Modal Buttons */
.modalButtons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.modalButtons button {
    background-color: #358ED7;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modalButtons button:hover,
.modalButtons button:focus {
    background-color: #2a73a0;
}
/* Reviews */

.reviewItem {
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #E1E8EE;
  }
  
  .reviewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .reviewName {
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  
  .reviewRating {
    font-size: 18px;
    color: gold; /* Adjust the color of stars */
  }
  
  .reviewDate {
    font-size: 12px;
    color: #666;
  }
  
  .reviewComment {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
/* Add review */
.product-review {
    margin-top: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-review h3 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .review-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .review-form textarea {
    font-size: 14px;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-height: 100px;
    resize: vertical;
    width: 100%;
    font-family: 'Arial', sans-serif;
    resize: none;
  }
  
  .rating-stars {
    display: flex;
    flex-direction: column;
  }
  
  .stars {
    display: flex;
    gap: 10px;
  }
  
  .star {
    font-size: 30px;
    transition: color 0.3s ease;
  }
  
  .error {
    color: #d9534f;
    font-size: 14px;
  }
  
  .product-review button {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .product-review button:hover {
    background-color: #0056b3;
  }

.showMoreBtn {
    color: rgb(92, 89, 89);
    background: transparent;
    border: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
}
.showMoreBtn:hover {
    color: #1767b2;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive Styling */
@media (max-width: 940px) {
    .container {
        flex-direction: column;
    }

    .left-column,
    .right-column {
        width: 100%;
    }

    .left-column img {
        width: 100%;
        height: auto;
        max-width: none;
    }
}

@media (max-width: 535px) {
    .product-description h1 {
        font-size: 28px;
    }

    .product-price p {
        font-size: 24px;
    }

    .product-quantity select {
        max-width: 100%;
    }

    .social-icons a {
        font-size: 28px;
    }
}
