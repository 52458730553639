@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.heroContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.sliderContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}

.headerText {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.subText {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .sliderContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .sliderContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .headerText {
    font-size: 2rem;
  }
  .subText {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .sliderContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .headerText {
    font-size: 1.5rem;
  }
  .subText {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .heroContainer {
    padding: 0;
    margin: 0;
    height: auto;
  }
}

@media (max-width: 480px) {
  .heroContainer {
    padding: 0;
    margin: 0;
    height: auto;
  }
}
