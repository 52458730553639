.policyContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

@media (min-width: 992px) {
    .shippingHeader {
        font-size: 1.5rem;
        margin-bottom: 20px;
        text-align: left;
    }
}

@media (max-width: 480px) {
    .shippingHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
}

@media (max-width: 750px) {
    .shippingHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.section {
    margin-bottom: 50px;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #555;
}

p {
    font-size: 16px;
    line-height: 1.5;
    color: #777;
}

ul, ol {
    list-style-position: outside;
    padding-left: 20px;
}

ul li, ol li {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    font-weight:normal;
}

a {
    color: #007bff;
    text-decoration: none;
}
