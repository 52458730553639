.card-header {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    color: #000;
    max-width: 100%;
}

.product-carousel-container {
    margin: 0 auto;
    padding-bottom: 1rem;
    max-width: 100%;
}

.carousel-arrow-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.46);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-color: #fff;
    transition: background-color 0.3s ease;
    z-index: 1;
    cursor: pointer;
    max-width: 100%;
}

.carousel-arrow-right {
    max-width: 100%;
    top: 50%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.46);
    transform: translateY(-50%);
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-color: #fff;
    transition: background-color 0.3s ease;
    z-index: 1;
    cursor: pointer;
    right: 0px;
}

.carousel-arrow:hover {
    background-color: rgba(58, 57, 57, 0.53);
}

.fa {
    font-size: 20px;
    color: #333;
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .product-carousel-container {
        margin: 0 auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-width: 100%;
    }
}
