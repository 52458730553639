.header {
    color: #000000;
}
.centerBtn{
    display: flex;
    justify-content: center; /* Center the container horizontally */
    align-items: center; /* Center the container vertically */

}
.inputContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
}
.errorText {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 90%; 
    width: 500px; 
}
.inputGroup {
    margin-bottom: 15px;
}

.inputGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.inputField {
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}
.inputFieldThemeColor {
    width: 94%;
    padding: 16px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}
.selectInputField {
    width: 105%;
    padding: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}

.submitButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 3px;
}

.closeButton {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
}

.submitButton:hover,
.closeButton:hover {
    background-color: #0056b3;
}

.textareaField {
    width: 92%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    resize: none;
}
.inlineInput {
    display: flex;
    justify-content: space-between;
}

.inlineInputItem {
    flex: 1;
    margin-right: 30px;
}

@media screen and (max-width: 600px) {
    .modalContent {
        width: 90%;
    }
}
@media screen and (max-width: 450px) {
    .modalContent {
        width: 90%;
    }
    .inlineInput {
        justify-content: space-between;
    }
}