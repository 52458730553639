.container {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
}

h2 {
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase;
    color: #000;
    font-weight:normal;
}

.description {
    text-align: center;
    width: 43%;
}

.client-image {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.client-image img{
    border-radius: 0.5rem;
}
.client-image span {
    margin-left: 10px;
}

.client-image img {
    width: 40px;
}

.review-section {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.review-item {
    width: 300px;
    padding: 10px;
    margin: 1rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.112); border: 1px solid rgba(187, 182, 182, 0.32);
    transition: all .2s linear;
}

.review-item:hover {
    border-color: #2180e0;
    transform: scale(1.01);
    background-color: rgba(255, 255, 255, 0.46);
    box-shadow: 0 0px 5px 0px #cbc0c0;
}
.reviewer{
    color: #000;
    font-weight:normal;
}

.top {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.top ul {
    display: flex;
    list-style: none;
}

.top ul li {
    padding-left: 4px;
    padding-top: 10px;
}

article p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 1rem;
}


@media screen and (max-width:700px) {
    .container {
        height: auto;
    }

    .description {
        width: 90%;
    }
}

@media screen and (max-width:375px) {
    .review-section {
        padding: 0;
    }

    .review-item {
        width: 100%;
    }

    .client-image {
        margin-bottom: 0.6rem;
    }

    .top {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

.social {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 50px;
    margin-top: 50px;
    text-align: center;
}