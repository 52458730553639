.footerBg {
    background-color: rgb(238, 238, 239);
    height: 100%;
    max-width: 100%;
}

.footer {
    padding: 3rem 1rem;
    display: block;
    max-width: 100%;
    text-align: center;
}

@media (min-width: 640px) {
    .footer {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (min-width: 1024px) {
    .footer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.footerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    
}

/* Business Information */
.footerBrand {
    flex: 1 1 200px; /* Grow and shrink, with a base width of 200px */
    margin: 10px;
    text-align: center;
}

.footerLogo {
    width: 100px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 10px;
}

.footerBrandName {
    font-size: 2rem;
    color: #000;
    margin-bottom: 10px;
}

.footerContact {
    font-size: 1rem;
    color: #000;
}

/* Sections */
.footerSection {
    flex: 1 1 200px; /* Grow and shrink, with a base width of 200px */
    margin: 10px;
}

.footerSectionTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #000;
}

.footerSubSectionTitle {
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-bottom: 10px;
    color: #000;
}

.footerNavItems {
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000;
    text-decoration: none;
    margin-bottom: 5px; 
    font-weight:300;
}

.footerNavItems:hover {
    color: #0862bc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footerContent {
        flex-direction: column;
        align-items: center;
    }

    .footerSection {
        margin: 10px 0;
        text-align: center;
    }
}

.footerIconBg {
    display: flex;
    margin-top: 2.5rem;
    justify-content: center;
}

.footerIcon {
    color: #000;
    font-size: 1.5rem;
    padding-left: 1rem;
}

.footerIconLinks {
    color: #000;
}

.footerIconLinks:hover {
    color: #05417e;
}

.footerCopyright {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #000;
}
