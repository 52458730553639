.banner {
    background-color: rgb(255, 255, 255); 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 20px;
    margin-top: 4.5rem;
}

.delivery-images img {
    width: 4rem;
    height: 3rem;
    margin-right: 2rem; 
}

.phone-number {
    font-size: 12px;
    color: #060b93;
}

.phone-number span {
    font-weight: bold;
    margin-left: 5px;
 }

 @media (min-width: 992px) {
 
.banner {
    background-color: rgb(255, 255, 255); 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 20px;
    margin-top: 6.2rem;
}    
 }