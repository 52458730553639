.policyContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
   }

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #000;
}

.section {
    margin-bottom: 50px;
}

h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #000;
}

p {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    font-weight:normal;
}

ul, ol {
    list-style-position: outside;
    padding-left: 20px;
}

ul li, ol li {
    font-size: 16px;
    line-height: 1.5;
    
    color: #000;
}

a {
    color: #000;
    font-weight: bolder;
    text-decoration: none;
}


 
  @media (min-width: 992px) {

    .policyHeader {
        font-size: 1.5rem;
        margin-bottom: 20px;
        text-align: left;
    
    }
    }
  
@media (max-width: 480px) {
    .policyHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
    
}
@media (max-width: 750px) {
    .policyHeader {
        text-align: center;
        font-size: 1rem;
        text-transform: uppercase;
        color: #000;
        text-align: left;
    }
}