.termsContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

@media (max-width: 480px) {
    .termsHeader {
        font-size: 1rem;
        margin-bottom: 20px;
        text-align: left;
    }
}
@media (max-width: 750px) {
    .termsHeader {
        font-size: 1rem;
        margin-bottom: 20px;
        text-align: left;
    }
}
@media (min-width: 992px) {

.termsHeader {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;

}
}
.section {
    margin-bottom: 50px;
}

h2 {
    font-size: 12px;
    text-align: left;
    margin-bottom:20px;
}

p {
    font-size: 16px;
    line-height: 1.5;
}

ol {
    list-style-type: decimal;
    padding-left: 30px;
    color: #555;
}

ol li {
    font-size: 16px;
    margin-bottom: 8px;
}
