.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 10px;
}

.featured-category-card {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    max-width: 100%;
}

.featured-category-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    text-decoration: none;
}

.card-content {
    text-align: center;
}

.featured-category-card img {
    width: 20rem;
    height: 20rem;
    border-radius: 0.2rem;
    justify-content: center;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-category-card h3 {
    margin-top: 10px;
}

.featured-category-card-button {
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.featured-category-card-button-link {
    text-decoration: none;
}

@media (max-width: 576px) {
    .featured-category-card {
        max-width: 100%;
    }

    .featured-category-card img {
        max-height: 300px;
    }
}

@media (min-width: 768px) {
    .featured-category-card {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .featured-category-card {
        max-width: 350px;
    }
}

@media (min-width: 1200px) {
    .featured-category-card {
        /* max-width: 400px; */
    }
}
