.container {
  padding: 20px;
}
#contact {
  padding: 30px 0;
}
 
@media (min-width: 992px) {
  
   .textheader {
    color: #000;
    margin-top: 50px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
  }
    
    .sectiontext {
      font-size: 1.5rem;
      font-weight: normal;
    color: #000;
    margin-top: 2px; 
    margin-bottom: 2px; 
    padding-bottom: 15px;
    text-align: center;
  }
  .contact-opening-container {
      display: flex; 
      justify-content: center;
      width: 100%;
  }

  .col-md-7, .col-md-5 {
    min-height: 1px;
    padding-right: 80px;
    padding-left: 50px;
  }

  .email-section {
      width: 70%;
      display: flex;
      justify-content: center; 
      
  }
}

@media (max-width: 750px) {
  .textheader {
    color: #000;
    margin-top: 50px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
  }
  
  .contact-opening-container {
      display: block; 
  }

  .col-md-7, .col-md-5 {
      max-width: 100%;
      padding: 20px;text-align: center;
  }

  .email-section {
      margin-top: 20px; 
      display: block; 
  }
}

@media (max-width: 480px) {
  .textheader {
    text-align: center;
    color: #000;
    margin-top: 50px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-weight: bolder;
   
  }
  .contact-opening-container {
      display: block; 
    }

  .col-md-7, .col-md-5 {
      max-width: 100%;
      padding: 20px;
      text-align: center;
  }
  .sectiontext {
    font-size: 1.5rem;
    font-weight: normal;
    color: #000;
    margin-top: 2px;
    padding-left: 5px;
    text-align: center;
}
  .email-section {
      margin-top: 20px;
      display: block; 
    }
}

.section-title {
  margin-bottom: 30px;
}

.section-title h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #1d2893 0%, #408de1 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}


.form-group {
  margin-bottom: 20px;
}

.form-control::placeholder {
  color: #000;
  opacity: 1;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.112);
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

 
.btn-custom {
  background-color: transparent;  
    color: #000;
      border: 2px solid #000; 
        border-radius: 50px;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;  
   font-weight: bold;
}

.btn-custom:hover {
  background-color: #0056b3;
}

 
#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.popup-bg {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-header {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.text-bold{
  font-weight: bolder;
}
.popup-text {
  font-size: 1rem;
  color: #666;
}

.popup-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.sectiontext {
  font-size: 1.5rem;
  color: #000;
  font-weight:300;
        margin-top: 2px;
        padding-left: 5px;
        text-align: center;
}


 
#footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
}

 
.email-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
}

 
.form-group {
  margin-bottom: 15px;
  
}

.form-group input,
.form-group textarea {
  margin: 0 auto; 
}

 
.social {
  padding: 0;
  text-align: center;
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; 
}

.social-list-item {
  display: inline-block;
  margin: 0 10px; 
}

.icon {
  font-size: 1.5rem; 
  color: #000;
   transition: color 0.3s; 
  }

.iconLinks {
  color: inherit; 
  text-decoration: none;  
}

.iconLinks:hover .icon {
  color: #86aaf4;
}
